/**
 * Establish initial state/context for the AudioPlayer component.
 *
 * @see: https://reactjs.org/docs/hooks-reference.html#usecontext
 */

import React from 'react'

/* Set the initial state */
export const initialAudioPlayerState = {
  isPlaying: false,
  playerIsOpen: false,
  currentTrack: {},
  updateCurrentTrack: () => {}
}

export const AudioPlayerContext = React.createContext(
  initialAudioPlayerState
)
