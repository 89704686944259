/**
 * This acts as a wrapper for the AudioPlayerCustomPlayer,
 * so that we can import that here withMediaProps.
 *
 * @see: https://github.com/souporserious/react-media-player#withmediaprops-decorator-props-exposed-under-thispropsmedia
 */

import React from 'react'
import { Media } from 'react-media-player'

import CustomPlayer from './AudioPlayerCustomPlayer'

const AudioPlayerView = () => (
  <Media>
    <CustomPlayer />
  </Media>
)

export default AudioPlayerView
