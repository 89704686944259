/**
 * Main view of the AudioPlayer component.
 *
 * IMPORTANT:
 *
 * Must be exported withMediaProps (HOC), in order to have
 * access to the `media` object (for play/pause events).
 *
 * @see: @see: https://github.com/souporserious/react-media-player#withmediaprops-decorator-props-exposed-under-thispropsmedia
 */

import React, { useEffect } from 'react'
import { Player, withMediaProps } from 'react-media-player'
import PropTypes from 'prop-types'

import Image from 'Image'
import SocialShare from 'SocialShare'

import useAudioPlayer from './_useAudioPlayer'
import TrackDetails from './AudioPlayerTrackDetails'
import TrackBar from './AudioPlayerTrackBar'
import CustomPlayPause from './AudioPlayerCustomPlayPause'

const AudioPlayerCustomPlayer = ({ media }) => {
  /* Get state and relevant functions from the context */
  const {
    currentTrack,
    isPlaying,
    handlePlay,
    handlePause
  } = useAudioPlayer()

  /**
   * Listen for changes on the AudioPlayer context (isPlaying).
   * @see: https://reactjs.org/docs/hooks-effect.html
   */
  /* eslint-disable */
  useEffect(() => {
    isPlaying ? media.play() : media.pause()
  }, [isPlaying])
  /* eslint-enable */

  return (
    <aside
      className='c-audio-player'
    >
      <div
        className='c-audio-player-artwork u-position-relative'
      >
        <figure
          className='u-img-full u-margin-bottom-none'
        >
          <Image
            image={currentTrack.show.artwork}
            width={80}
            minWidth={80}
            alt={`The artwork for the ${currentTrack.show.title} podcast`}
          />
        </figure>
        <CustomPlayPause isLoading={media.isLoading} />
      </div>
      <div className='c-audio-player-main'>
        <div className='c-audio-player-main__header'>
          <TrackDetails />
        </div>
        <div className='c-audio-player-main__footer u-fs-11'>
          <TrackBar />
        </div>
      </div>
      <div className='c-audio-player-share'>
        <SocialShare
          slug={currentTrack.slug}
          title={currentTrack.show.title}
          shape={`circle`}
          context={`audio`}
        />
      </div>
      <Player
        src={currentTrack.url}
        onPlay={handlePlay}
        onPause={handlePause}
        autoPlay
      />
    </aside>
  )
}

AudioPlayerCustomPlayer.propTypes = {
  media: PropTypes.object.isRequired
}

export default withMediaProps(AudioPlayerCustomPlayer)
