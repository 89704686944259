import React from 'react'
import { controls, withMediaProps } from 'react-media-player'

const {
  SeekBar,
  Duration,
  CurrentTime
} = controls

const AudioPlayerTrackBar = () => (
  <div
    className='c-audio-player-progress u-margin-top-tiny'
  >
    <div
      className='c-audio-player-progress__time'
    >
      <CurrentTime />
    </div>
    <div
      className='c-audio-player-progress__track'
    >
      <SeekBar
        className='c-audio-player-progress__range'
      />
    </div>
    <div
      className='c-audio-player-progress__time u-ta-r'
    >
      <Duration />
    </div>
  </div>
)

export default withMediaProps(AudioPlayerTrackBar)
