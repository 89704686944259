import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import AudioPlayer from 'AudioPlayer'

const AppView = ({ children }) => (
  <Fragment>
    { children }
    <AudioPlayer />
  </Fragment>
)

AppView.propTypes = {
  children: PropTypes.node.isRequired
}

export default AppView
