/* The entry file for AudioPlayer component */

import React from 'react'

import useAudioPlayer from './_useAudioPlayer'
import View from './AudioPlayerView'

const AudioPlayer = () => {
  const { currentTrack } = useAudioPlayer()

  return (
    currentTrack &&
      <View />
  )
}

export default AudioPlayer
