import React, { Fragment, useState, useEffect } from 'react'

const IsTouchDevice = ({ children }) => {
  const [isTouch, setIsTouch] = useState(false)

  useEffect(() => {
    const checkIfTouchDevice = () => {
      // https://coderwall.com/p/i817wa/one-line-function-to-detect-mobile-devices-with-javascript
      return (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1)
    }

    setIsTouch(checkIfTouchDevice())
  }, [])

  return isTouch ? <Fragment>{children}</Fragment> : ``
}

export default IsTouchDevice
