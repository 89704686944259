import React from 'react'
import classNames from 'classnames'

import services from './services'

const SocialShareItem = ({ service, url, title, context, dark }) => {
  const {
    name,
    url: fullUrl,
    icon
  } = services(service, url, title)

  return (
    <li className={classNames(`c-social-share__item c-social-icon--${name.toLowerCase()} c-social-icon c-social-icon--${name.toLowerCase()}`, {
      [`c-social-icon--dark`]: dark
    })}>
      <a
        className={classNames(`c-social-share__link o-fill-parent u-position-relative u-dis-b`, {
          [`c-social-share__link--${context}`]: context
        })}
        href={fullUrl}
        target='_blank'
        rel='noopener noreferrer'
      >
        { icon }
        <span className='u-hide-visually'>
          {name}
        </span>
      </a>
    </li>
  )
}

export default SocialShareItem
