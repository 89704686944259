import React from 'react'

import { ReactComponent as TwitterSVG } from 'assets/img/svg/twitter.svg'
import { ReactComponent as FacebookSVG } from 'assets/img/svg/facebook.svg'
import { ReactComponent as InstagramSVG } from 'assets/img/svg/instagram.svg'
import { ReactComponent as EmailSVG } from 'assets/img/svg/email.svg'
import { ReactComponent as WhatsAppSVG } from 'assets/img/svg/whatsapp.svg'

const HamburgerIcon = () => (
  <span
    className='c-hamburger'
  >
    <span
      className='c-hamburger__line'
    />
    <span
      className='c-hamburger__line'
    />
    <span
      className='c-hamburger__line'
    />
  </span>
)

const CloseIcon = () => (
  <span
    className='c-close'
  >
    <span
      className='c-close__line'
    />
    <span
      className='c-close__line'
    />
  </span>
)

const PlayIcon = () => (
  <span
    className='c-play'
  >
    <span
      className='c-play__triangle'
    />
  </span>
)

const PauseIcon = () => (
  <span
    className='c-pause'
  >
    <span
      className='c-pause__inner'
    />
  </span>
)

const FacebookIcon = () => (
  <FacebookSVG />
)

const TwitterIcon = () => (
  <TwitterSVG />
)

const InstagramIcon = () => (
  <InstagramSVG />
)

const WhatsAppIcon = () => (
  <WhatsAppSVG />
)

const EmailIcon = () => (
  <EmailSVG />
)

export {
  HamburgerIcon,
  CloseIcon,
  PlayIcon,
  PauseIcon,
  FacebookIcon,
  TwitterIcon,
  InstagramIcon,
  WhatsAppIcon,
  EmailIcon
}
