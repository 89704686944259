/**
 * CSS Min Width breakpoints
 * Same values as in `client/scss/10_settings/_grid-settings.scss`
 */
export default {
  sm: 360,
  md: 738,
  lg: 1060,
  xl: 1252,
  container: 1504
}
