import React from 'react'
import { withMediaProps } from 'react-media-player'

import { ReactComponent as PauseIcon } from 'assets/img/svg/pause.svg'
import { ReactComponent as PlayIcon } from 'assets/img/svg/play.svg'
import { ReactComponent as LoadingIcon } from 'assets/img/svg/loading.svg'

const Button = ({ handleClick, isPlaying }) => (
  <button
    type='button'
    className='c-audio-player__btn o-disc-button'
    onClick={handleClick}
  >
    {
      isPlaying
        ? <PauseIcon />
        : <PlayIcon />
    }
  </button>
)

const Loading = () => (
  <span
    className='c-audio-player__btn o-disc-button'
  >
    <LoadingIcon
      className='o-loading-animation'
    />
  </span>
)

const AudioPlayerCustomPlayPause = ({ media }) => {
  const {
    isPlaying,
    isLoading
  } = media

  const handlePlayPause = () => {
    media.playPause()
  }

  return (
    isLoading
      ? <Loading />
      : <Button
        handleClick={handlePlayPause}
        isPlaying={isPlaying}
      />
  )
}

export default withMediaProps(AudioPlayerCustomPlayPause)
