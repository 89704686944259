import React, { useState } from 'react'

import View from './AppView'
import { AudioPlayerContext, initialAudioPlayerState } from 'AudioPlayer/AudioPlayerContext'

import 'assets/scss/app.scss'

const App = ({ props, children }) => {
  const [state, setState] = useState(initialAudioPlayerState)

  // todo: store currentTime
  // todo: store isPlaying
  // todo: store track data in localStorage

  return (
    <AudioPlayerContext.Provider
      value={[state, setState]}
    >
      <View>
        { children }
      </View>
    </AudioPlayerContext.Provider>
  )
}

export default App
