/* eslint-disable jsx-a11y/alt-text */
// Libraries
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// Utils
import cssBreakpoints from '../../utilities/cssBreakpoints'

const previewWidth = 100
const defaultDpr = 2
// Max width of images supported on uploadcare
const defaultMaxWidth = 3000

class Image extends Component {
  /**
   * Converts an image to an IMGIX one
   * @param  {String} options.src    URL of image on
   * @param  {Number} options.width  Desired width of image
   * @param  {Number} options.dpr    Desired dpr of image
   * @return {String}                IMGIX image url
   */
  static makeUrl ({ src, width, dpr, ...otherProps }) {
    const imageDpr = dpr || defaultDpr
    // Get the default attributes, add an extras
    const params = Object.entries({
      format: 'auto',
      srgb: 'fast',
      quality: 'lighter',
      resize: `${otherProps.blur ? width : (width * imageDpr)}x`,
      ...otherProps
    // Loop through each item, and convert it to the format mentioned below
    }).map((param) => {
      const [key, value] = param
      return `-/${key}/${value}/`
    }).join('')

    // Example output:
    // https://ucarecdn.com/961c7a96-cb8b-437f-9415-512f2239db67/-/format/auto/-/srgb/fast/-/resize/1764x/-/quality/lightest/882.jpg
    return `${src}${params}${width}.jpg`
  }

  /**
   * Convert a named breakpoint to a media query (for the `sizes` attribute on
   * the img tag). If the named breakpoint doesn't exist, then just return the
   * value as
   * @param  {String|Number} breakpointName
   * @return {String}
   */
  static breakpoint (breakpointName) {
    /**
     * Returns a string with a max-width media query
     * @param  {Number} width
     * @return {String}
     */
    const breakpointToMediaQuery = (width) => {
      return `(max-width: ${width / 16}em)`
    }

    if (cssBreakpoints[breakpointName]) {
      return breakpointToMediaQuery(cssBreakpoints[breakpointName] - 1)
    } else if (Number.isInteger(breakpointName)) {
      return breakpointToMediaQuery(breakpointName)
    } else {
      return new Error('`width` param not a named breakpoint or integer')
    }
  }

  /**
   * Rename object keys to another name, based on the `keysMap`
   * @param  {Object} keysMap
   * @param  {Object} obj
   * @return {Object}
   */
  renameKeys (keysMap, obj) {
    return Object
      .keys(obj)
      .reduce((acc, key) => {
        const renamedObject = {
          [keysMap[key] || key]: obj[key]
        }
        return {
          ...acc,
          ...renamedObject
        }
      }, {})
  }

  /**
   * Generate a single `srcset` value
   * @param  {Number} width
   * @return {String}
   */
  singleSrcSet (width) {
    const { image } = this.props

    return `${Image.makeUrl({
      src: image,
      width: width
    })} ${width}w`
  }

  /**
   * Generate values for the `srcset` attribute of the <img/> tag
   * @return {String}
   */
  steppedSrcSets ({ image, width }) {
    const { minWidth } = this.props
    const defaultMinWidth = minWidth || 400

    // If the image is small enough that it doesn't need multiple sizes, or the
    // there are other required values missing, then return `null`
    if (width < defaultMinWidth) {
      return null
    }

    // The split between each 'step'
    const stepAmount = 100
    const sets = []

    // If the desired image width isn't divisible by 100, such as 738px, make
    // that the first image
    if (defaultMinWidth % 100 !== 0) {
      sets.push(this.singleSrcSet(defaultMinWidth))
    }

    // Generate images that are 100px widths apart
    for (let transformedImageWidth = (Math.ceil(defaultMinWidth / 100)) * 100; transformedImageWidth <= width; transformedImageWidth += stepAmount) {
      // Uploadcare will only handle images up to 3000px
      if ((transformedImageWidth * defaultDpr) <= defaultMaxWidth) {
        sets.push(this.singleSrcSet(transformedImageWidth))
      }
    }

    // If the desired image width isn't divisible by 100, such as 738px, make
    // that the last image
    if (width % 100 !== 0) {
      sets.push(this.singleSrcSet(width))
    }

    return sets.join(', ')
  }

  /**
   * Render the preview image, which is blurred and low quality
   * @return {String} - HTML markup for the component
   */
  renderPreviewImage ({ height, image, width }) {
    const { alt } = this.props

    // Get the IMGIX url for the preview blurred image
    const previewUrl = Image.makeUrl({
      src: image,
      width: previewWidth,
      dpr: 1,
      quality: 'lightest',
      blur: 100
    })

    return (
      <img
        src={previewUrl}
        width={width}
        className='c-lazy-image__preview'
        alt={alt ? `Preview: ${alt}` : null}
      />
    )
  }

  /**
   * Render the lazyloaded image
   * @return {String} - HTML markup for the component
   */
  renderLazyImage ({ defaultImageProps }) {
    const renameKeysMap = {
      src: 'data-src',
      srcSet: 'data-srcset',
      sizes: 'data-sizes'
    }

    const lazyImageProps = this.renameKeys(renameKeysMap, defaultImageProps)

    lazyImageProps.src = ''
    lazyImageProps.className = 'c-lazy-image__main lazyload'

    return (
      <img {...lazyImageProps} />
    )
  }

  renderImage () {
    const { alt, className, image, index, lazyload, sizes } = this.props
    let { width } = this.props

    // Max width is 3000px, so if it's more than that, just use 3000px
    if ((width * defaultDpr) > defaultMaxWidth) {
      width = defaultMaxWidth / defaultDpr
    }

    const isLazyload = (index && index >= 1) || lazyload

    const defaultImageProps = {
      src: Image.makeUrl({
        src: image,
        width: width
      }),
      width: width,
      alt: alt,
      sizes: sizes && Array.isArray(sizes) ? sizes.join(', ') : sizes,
      srcSet: this.steppedSrcSets({ width, image }),
      className: className
    }

    if (isLazyload) {
      return (
        <div className={classNames('c-lazy-image', className)}>
          { this.renderLazyImage({ defaultImageProps }) }
          { this.renderPreviewImage({ image, width }) }
        </div>
      )
    }

    return (
      <img {...defaultImageProps} />
    )
  }

  renderSources () {
    return this.props.mobileImageWidths.map((width, index) =>
      <source
        media={`(max-width: ${(width / 16) - 0.01}em)`}
        srcSet={Image.makeUrl({
          src: this.props.mobileImage,
          width: width,
          dpr: 1
        })}
        key={index}
      />
    )
  }

  /**
   * Render the picture, lazy or normal
   * @return {String} - HTML markup for the component
   */
  renderPicture () {
    const { mobileImageWidths } = this.props

    if (this.lazyload) {
      // Get the largest image width from the `mobileImageWidths` array, and
      // use that as the maximum breakpoint, so there is only 1 <source /> for
      // the preview image
      const widestImage = Math.max(...mobileImageWidths)

      return (
        <div className='c-lazy-image'>
          <picture>
            { this.renderSources([previewWidth], widestImage, { blur: 100, dpr: 1 }) }
            { this.renderPreviewImage() }
          </picture>
          <picture>
            { this.renderSources(mobileImageWidths) }
            { this.renderLazyImage() }
          </picture>
        </div>
      )
    }

    return (
      <picture>
        { this.renderSources(mobileImageWidths) }
        { this.renderImage() }
      </picture>
    )
  }

  render () {
    if (this.props.mobileImage) {
      return this.renderPicture()
    }

    return this.renderImage()
  }
}

Image.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  image: PropTypes.string.isRequired,
  index: PropTypes.number,
  lazyload: PropTypes.bool,
  minWidth: PropTypes.number,
  mobileImage: PropTypes.string,
  mobileImageWidths: PropTypes.array,
  sizes: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  width: PropTypes.number.isRequired
}

Image.defaultProps = {
  mobileImageWidths: [380, 440, 540, 640, 738]
}

export default Image
/* eslint-enable jsx-a11y/alt-text */
