/**
 * Renders a component with the details of the currently
 * playing audio.
 */

import React from 'react'
import { Link } from 'gatsby'

import useAudioPlayer from './_useAudioPlayer'

const AudioPlayerTrackDetails = () => {
  /* Get currentTrack from context */
  const { currentTrack } = useAudioPlayer()
  const {
    show,
    name,
    slug
  } = currentTrack

  return (
    <header
      className='c-audio-player-track-details u-lh-1'
    >
      <h4
        className='c-audio-player-track-details__heading u-fs-14 u-margin-bottom-none u-fw-normal'
      >
        <Link
          to={show.slug}
          className='u-color-white'
        >
          {show.title}
        </Link>
      </h4>
      <h5
        className='c-audio-player-track-details__sub-heading u-fs-14 u-margin-bottom-none  u-fw-normal u-color-smoke'
      >
        <Link
          to={slug}
          className='u-color-smoke'
        >
          {name}
        </Link>
      </h5>
    </header>
  )
}

export default AudioPlayerTrackDetails
