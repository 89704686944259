import React from 'react'
import classNames from 'classnames'

import { ReactComponent as ShareIcon } from 'assets/img/svg/share.svg'

import IsTouchDevice from 'utilities/IsTouchDevice'

import SocialShareItem from './SocialShareItem'

const SocialShareView = ({ url, title, shape, context, visible, handleClick, dark }) => (
  <ul className={`c-social c-social--${context} o-cleanlist`}>
    <li className={`c-social__item`}>
      <button
        className={classNames('c-social__link o-button-reset', {
          [`c-social__link--${shape}`]: shape,
          [`c-social__link--hidden`]: visible && context !== 'audio',
          [`c-social__link--dark`]: dark
        })}
        onClick={handleClick}
      >
        <span className='c-social__icon u-dis-ib'>
          <ShareIcon />
        </span>
        <span
          className={`c-social__text ${shape === `circle` ? `u-hide-visually` : ``}`}
        >
          Share
        </span>
      </button>
      <ul className={classNames('c-social-share o-fill-parent o-cleanlist', {
        'c-social-share--visible': visible
      })}>
        {
          ['facebook', 'twitter', 'email', 'whatsapp'].map((service, key) => {
            if (service === 'whatsapp') {
              return (
                <IsTouchDevice key={key}>
                  <SocialShareItem
                    service={service}
                    url={url}
                    title={title}
                    context={context}
                    dark={dark}
                  />
                </IsTouchDevice>
              )
            } else {
              return (
                <SocialShareItem
                  service={service}
                  url={url}
                  title={title}
                  key={key}
                  context={context}
                  dark={dark}
                />
              )
            }
          })
        }
      </ul>
    </li>
  </ul>
)

export default SocialShareView
