import React from 'react'

import { TwitterIcon, FacebookIcon, WhatsAppIcon, EmailIcon } from 'Icons'

const services = (service, url, title) => {
  const allServices = {
    facebook: {
      name: `Facebook`,
      url: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
      icon: <FacebookIcon />
    },
    email: {
      name: `Email`,
      url: `mailto:?subject=${encodeURIComponent(title)}&body=${url}`,
      icon: <EmailIcon />
    },
    whatsapp: {
      name: `WhatsApp`,
      url: `whatsapp://send?text=${encodeURIComponent(`${url}`)}`,
      icon: <WhatsAppIcon />
    },
    twitter: {
      name: `Twitter`,
      url: `https://twitter.com/intent/tweet?text=${encodeURIComponent(`${title} from @Stakhanov ${url}`)}`,
      icon: <TwitterIcon />
    }
  }

  return allServices[service]
}

export default services
