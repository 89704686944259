/**
 * A custom hook built on React's useContext.
 *
 * Gives us an easier method to update and access state in
 * other components around the application, as and when we need to.
 *
 * Based on the tutorial from James King at Upmostly.
 * @see https://upmostly.com/tutorials/how-to-use-the-usecontext-hook-in-react
 *
 * @see: https://reactjs.org/docs/hooks-intro.html
 * @see: https://reactjs.org/docs/hooks-reference.html#usecontext
 */

import { useContext } from 'react'

import { AudioPlayerContext } from './AudioPlayerContext'

const useAudioPlayer = () => {
  /* Calls the initial state from the Context file */
  const [state, setState] = useContext(AudioPlayerContext)

  /* Updates the state when a user plays a track */
  const handlePlay = () => {
    setState(state => ({
      ...state,
      isPlaying: true,
      playerIsOpen: true
    }))
  }

  /* Updates the state when a user pauses a track */
  const handlePause = () => {
    setState(state => ({
      ...state,
      isPlaying: false
    }))
  }

  /**
   * Updates the state when a user either:
   *
   * - Selects a new track
   * - Hits the play/pause button on the currently playing track
   */
  const handleTrackUpdate = (track, shouldPlay) => {
    setState(state => ({
      ...state,
      currentTrack: track,
      isPlaying: shouldPlay,
      playerIsOpen: true
    }))
  }

  /**
   * Return all our variables & functions so that other
   * files can use them.
   */
  return {
    handlePlay,
    handlePause,
    handleTrackUpdate,

    /* Check the state exists, because we're using SSR */
    isPlaying: state && state.isPlaying,
    currentTrack: state && state.currentTrack.url ? state.currentTrack : null
  }
}

export default useAudioPlayer
